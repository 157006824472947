import {CustomElement} from "../custom-element";
@CustomElement({
  selector: 'nama-tab-group'
})
export default class NamaTabGroup extends HTMLElement{
  constructor(){
    super();
  }

  connectedCallback() {
    //
  }
}


