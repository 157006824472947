import {CustomElement} from "../custom-element";

@CustomElement({
  selector: 'nama-modal'
})
export default class NamaModal extends HTMLElement{

    public modalCourtin: HTMLElement;

    constructor(){
      super();
      this.modalCourtin = document.createElement('div');
    }

    courtin(){
      this.modalCourtin.classList.add("nama-modal-courtin");
      if(document.querySelectorAll(".nama-modal-courtin").length <= 0){
        if(this.parentElement){
          this.parentElement.appendChild(this.modalCourtin);
        }
      } else {
        let nodes = document.querySelectorAll('.nama-modal-courtin');
        this.modalCourtin = (nodes[0] as HTMLElement);
      }
      this.style.display = 'block';
    }

    connectedCallback() {
      this.style.display = 'none';
    }

    blur(){
      document.querySelectorAll("main")[0].classList.add('blur');
    }

    show(){
      this.style.display = 'block';
      this.blur();
      this.courtin();
    }

    close(){
      this.style.display = 'none';
      this.modalCourtin.style.display = 'none';
      document.querySelectorAll("main")[0].classList.remove('blur');
    }

}


