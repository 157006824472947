import {CustomElement} from "../custom-element";

@CustomElement({
  selector: 'nama-tab'
})
export default class NamaTab extends HTMLElement{

  public active:boolean = false;
  public showEvent:CustomEvent;
  public hideEvent:CustomEvent;

  constructor(id:string){
    super();
    if(id){
      this.id = id;
    }
    this.showEvent = new CustomEvent("show", {'target': this} as CustomEventInit<{}>);
    this.hideEvent = new CustomEvent("hide", {'target': this} as CustomEventInit<{}>);
  }

  show(){
    this.hideAllBrothers();
    this.dispatchEvent(this.showEvent);
    this.style.display = "block";
  }

  hide(){
    this.dispatchEvent(this.hideEvent);
    this.style.display = "none";
  }

  hideAllBrothers(){
    if(this.parentElement){
      let tabQuery = this.parentElement.querySelectorAll("nama-tab");
      for (let tab = 0; tab <= tabQuery.length; tab++) {
        let tabEl = tabQuery[tab] as NamaTab;
        if(tabEl){
          tabEl.style.display = "none";
          let _hideEvent = new CustomEvent("hide", {'target': tabEl} as CustomEventInit<{}>);
          tabEl.dispatchEvent(_hideEvent);
        }
      }
    }
  }

  connectedCallback() {
    let activeAttr = this.attributes.getNamedItem("active");
    if(activeAttr){
      this.active = Boolean(activeAttr.value);
    }
    if(this.active){
      this.show();
    } else {
      this.hide();
    }
    if(location.hash !== ""){
      if(location.hash === `#${this.id}` ){
        this.show();
      } else {
        this.hide();
      }
    }
  }
}


