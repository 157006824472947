import {CustomElement} from "../custom-element";
import isUndefined from "lodash/isUndefined";

@CustomElement({
  selector: 'nama-code'
})
export default class NamaCode extends HTMLElement{

  public observer: MutationObserver;
  constructor(){
    super();
    this.observer = new MutationObserver(changes => {
      // console.log(this.render(this.innerHTML).innerHTML);
      let code = this.render(this.innerHTML)
      this.innerHTML = "";
      this.appendChild(code);
      this.observer.disconnect();
    });
  }

  escapeHtml(unsafe:string) {
    return unsafe
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;")
         .replace(/\\n/g, "\n").trim();
  }

  render(code:string){
    let preEl = document.createElement('pre');
    let codeEl = document.createElement('code');
    codeEl.innerHTML = this.escapeHtml(code);
    preEl.appendChild(codeEl);
    preEl.classList.add("prettyprint");
    preEl.classList.add("dark");
    preEl.classList.add("lang-html");
    return preEl;
  }

  connectedCallback(){

    if(isUndefined(this.innerHTML)){
      let code = this.render(this.innerHTML)
      this.innerHTML = "";
      this.appendChild(code);
      this.observer.disconnect();
    }

    this.observer.observe(this, {
        childList: true
    });
  }
}
