import {CustomElement} from "../custom-element";
import NamaModal from "../molecules/modal.molecule";
import isUndefined from "lodash/isUndefined";
import NamaTab from '../atoms/tab.atom';

@CustomElement({
  selector: 'nama-list'
})
export default class NamaList extends HTMLElement{

    constructor(){
      super();
    }

    connectedCallback() {
      //
    }
}

@CustomElement({
  selector: 'nama-item'
})
export class NamaItem extends HTMLElement{
  public href:Attr | any;
  public target:Attr | any;

  constructor(href?:string, target?:string){
    super();
    if(!isUndefined(href)){
      let hrefAttr = document.createAttribute('href');
      hrefAttr.value = href;
      this.attributes.setNamedItem(hrefAttr);
    }
    if(!isUndefined(target)){
      let targetAttr = document.createAttribute('target');
      targetAttr.value = target;
      this.attributes.setNamedItem(targetAttr);
    }
  }

  connectedCallback() {
      this.href = this.attributes.getNamedItem('href');
      this.target = this.attributes.getNamedItem('target');
      this.addEventListener('click' , (e:any) => {
        e.stopPropagation();
        this.click();
      });
  }

  deactiveAllBrothers(){
    if(this.parentElement){
      let tabQuery = this.parentElement.querySelectorAll("nama-button");
      for (let tab = 0; tab <= tabQuery.length; tab++) {
        let tabEl = tabQuery[tab] as NamaTab;
        if(tabEl){
          tabEl.classList.remove("active");
        }
      }
    }
    this.classList.add("active");
  }

  click() {
    if(this.href !== null){
      if(this.target == null){
        location.href = this.href.value;
      } else if(this.target.value === '_blank') {
        let win = window.open(this.href.value, '_blank');
        if(win !== null){
          win.focus();
        }
      } else if(this.target.value === '_tab') {
        let istanceTab:NamaTab | null = document.querySelector('#'+ this.href.value);
          if(istanceTab){
            istanceTab.show();
            this.deactiveAllBrothers();
          }
      } else if(this.target.value === '_modal') {
          let istanceModal:NamaModal | null = document.querySelector('#'+ this.href.value);
          if(istanceModal){
            istanceModal.show();
          }
      } else if(this.target.value === '_modal_close') {
        let istanceModal:NamaModal | null = document.querySelector('#'+ this.href.value);
        if(istanceModal){
          istanceModal.close();
        }
      }

    }
  }
}


