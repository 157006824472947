import {CustomElement} from "../custom-element";
import NamaIcon from './icon.atom';

@CustomElement({
  selector: 'nama-input'
})
export default class NamaInput extends HTMLElement{
  constructor(){
    super();

  }

  connectedCallback() {
    if(this.parentElement){
      this.generate(this.parentElement);
    }
  }

  generate(parentElement:HTMLElement){
    let icon = this.attributes.getNamedItem('icon');
    let gInput = this.generateInput();

    gInput.addEventListener('focusout', ()=>{
      gInput.classList.remove('valid');
      gInput.classList.remove('invalid');
      gInput.classList.add(gInput.checkValidity() ? 'valid' : 'invalid');
    })

    let wrapped = (icon !== null);
    if(wrapped){
      let div = document.createElement('div');

      div.classList.add("nama-input-wrapper");

      for (let c = this.classList.length - 1; c >= 0; c--) {
        div.classList.add(this.classList[c]);
      }
      gInput.addEventListener('focus', ()=>{
        div.classList.add('focus');
      })
      gInput.addEventListener('focusout', ()=>{
        div.classList.remove('focus');
      })

      div.appendChild(gInput);
      div.appendChild(this.addIcon(icon));
      parentElement.replaceChild(div, this);
    } else {
      parentElement.replaceChild(gInput, this);
    }
  }

  generateInput (){
    let input = document.createElement('input');
    for (let i = this.attributes.length - 1; i >= 0; i--) {
      let at = this.attributes.item(i);
      if(at){
        let cl = at.cloneNode();
        if(at.name !== 'class'){
          input.setAttribute(at.name, at.value);
        }
      }
    }
    input.classList.add("nama-input");
    for (let c = this.classList.length - 1; c >= 0; c--) {
      input.classList.add(this.classList[c]);
    }
    return input;
  }

  addIcon(iconAttr:Attr|null){
    let icon;
    if(iconAttr){
      icon = new NamaIcon(iconAttr.value);
    }
    return icon || document.createElement('img');
  }
}
