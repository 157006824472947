require('./index.scss');

import NamaButton from "./atoms/button.atom"
import NamaIcon from "./atoms/icon.atom"
import NamaInput from "./atoms/input.atom"
import NamaBubble from "./atoms/bubble.atom"
import NamaAvatar from "./atoms/avatar.atom"
import NamaCode from "./atoms/code.atom"
import NamaTab from "./atoms/tab.atom"

import NamaModal from "./molecules/modal.molecule"
import NamaCards from "./molecules/cards.molecule"
import NamaList from "./molecules/list.molecule"
import NamaChoices from "./molecules/choices.molecule"
import NamaTabGroup from "./molecules/tab.group.molecule"

export { NamaButton , NamaIcon , NamaInput , NamaBubble ,
         NamaAvatar , NamaCode , NamaTab , NamaModal , NamaCards , NamaList ,
         NamaChoices , NamaTabGroup }
