
require("../node_modules/@webcomponents/custom-elements/src/native-shim.js")
require("../node_modules/@webcomponents/custom-elements/custom-elements.min.js")

var authCss = require("./ui/auth/auth.scss");

var head = document.querySelector("head");
var style = document.createElement("style");
style.innerHTML = authCss;
head.appendChild(style);

window.NamaPatterns = require("./patterns/auth.ts").default;

